import defaultAttrs from './default-attributes'

export default {
  attributes: [
    ...defaultAttrs,
  //   {
  //     title: "Пользовательские атрибуты",
  //     fields: [
  //       { name: "attribute_1", title: "Название атрибута" },
  //       { name: "attribute_2", title: "Название атрибута 2" },
  //     ],
  //   },
  ],

  data: {
    file_code: '21.010.03-pl1-AC2',
    percent: '25%',
    full_project_name: '21.010.03 Газго',
    project_name: 'Газго',
    customer: 'ТОО ГазгоГруп',
    project_cipher: '21.010.03',
    contract_number: 'Gazgogroup-Contract-34693-56',
    started_at: '30.10.2020',
    created_at: '28.10.2020',
    created_by: 'Новиков Николай',
    updated_at: '19.04.2021',
    updated_by: 'Болатов Ерлан',
    attribute_1: 'Данные',
    attribute_2: 'Данные',
  },
}
