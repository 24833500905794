export default [
  // {
  //   fields: [{ name: "percent", title: "Процент исполнения", icon: "percent" }],
  // },
  {
    fields: [
      { name: 'full_project_name', title: 'Полное название проекта', },
      { name: 'project_name', title: 'Краткое название проекта', },
      { name: 'customer', title: 'Заказчик', },
      { name: 'project_cipher', title: 'Шифр проекта', },
      { name: 'contract_number', title: 'Номер договора', },
      { name: 'started_at', title: 'Дата начала проекта', },
      { name: 'created_at', title: 'Дата создания проекта', },
      { name: 'percent', title: 'Процент исполнения', },
      { name: 'created_by', title: 'Создан', },
      { name: 'updated_by', title: 'Обновлен', },
      { name: 'updated_at', title: 'Дата обновления', },
    ],
  },
]
