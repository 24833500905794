import defaults from "../../data/attributes/default";

export default async function (id) {
  let result = {};
  try {
    result = (await import("../../data/attributes/" + id)).default;
  } catch (error) {
    console.warn(
      `Couldnt find module by id: ${id}. Default attributes returned instead.`
    );
    result = defaults;
  }

  return result;
}
